<template>
  <div>
    <div>
      <div class="flex align-items marginBottom10 flex-wrap">
        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">区域编号:</span>
          <div class="width200">
            <el-input
              v-model="searchData.code"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">区域名称:</span>
          <div class="width200">
            <el-input
              v-model="searchData.name"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="span marginRight10">区域状态 : </span>
          <div class="width200">
            <el-select
              v-model="searchData.state"
              clearable
              @change="handleSearch"
            >
              <el-option
                value=""
                label="请选择"
              ></el-option>
              <el-option
                :value="1"
                label="正常"
              ></el-option>
              <el-option
                :value="2"
                label="冻结"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            class="marginLeft10"
          >查询</el-button>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="info"
            icon="el-icon-refresh-right"
            @click="handleReset"
            class="marginLeft10"
          >重置</el-button>
        </div>
      </div>
    </div>

    <avue-crud
      ref="ongoingTask"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          v-if="!row.check"
          size="mini"
          @click="rowClick(row)"
        >选择</el-button>
        <el-button
          type="text"
          v-else
          size="mini"
          disabled
        >已选择</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { addRegionList } from "@/api/scan/index";
export default {
  name: "goods",
  props: {
    defaultData: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectRow: "",

      searchData: {
        code: "",
        name: "",
        state: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "mini",
        selection: false, // 显示多选框
        reserveSelection: false,
        rowKey: "id",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "区域编号",
            prop: "code",
          },
          {
            label: "区域名称",
            prop: "name",
          },
          {
            label: "区域状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "正常"
                : row.state === 2
                ? "冻结"
                : row.state;
            },
          },
        ],
      },
    };
  },
  watch: {
    show(value) {
      this.searchData = {};
      this.page.currentPage = 1;
      this.onLoad();
    },
  },
  methods: {
    rowClick(row) {
      this.selectRow = row;
      this.selectClassify();
    },

    onLoad() {
      this.showLoading = true;
      addRegionList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;

          if (this.show) {
            // this.$nextTick(() => {
            this.selectRow = this.defaultData;
            this.tableData.map((item) => {
              if (item.id === this.selectRow.id) {
                item.check = true;
              } else {
                item.check = false;
              }
            });
            // });
          } else {
            this.selectRow = {};
          }
        }
      });
    },
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleReset() {
      this.searchData = {};
      this.page.currentPage = 1;
      this.onLoad();
    },
    selectClassify() {
      this.$emit("change", this.selectRow);
    },
  },
};
</script>

<style scoped>
/* .crud /deep/.el-card__body .el-form .el-table .el-table__fixed-right {
  height: 100% !important;
} */
</style>

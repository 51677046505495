import { get, post, put, Delete } from "@/api/axios";

// 白名单设置-列表
export const whiteList = (params) => get("/web/worker/manage/list", params);

// 白名单设置-会员-列表
export const userList = (params) => get("/web/worker/manage/user/list", params);

// 白名单设置-新增-区域-列表
export const addRegionList = (params) =>
  get("/web/worker/manage/region/list", params);

// 白名单设置-新增
export const whiteAdd = (params) => post("/web/worker/manage/add", params);

// 白名单设置-删除
export const deleteWhite = (params) =>
  Delete("/web/worker/manage/delete/" + params.id, "");

// 白名单设置-查看
export const whiteView = (params) =>
  get("/web/worker/manage/view/" + params.id, "");

// 白名单设置-修改
export const whiteUpdate = (params) => put("/web/worker/manage/update", params);

// 白名单设置-状态-修改
export const whiteStateUpdate = (params) => put("/web/worker/manage/state/update", params);


<template>
  <div class="main-content">
    <h3 class="marginBottom20">
      {{ type === "create" ? "新增工作人员" : "编辑工作人员" }}
    </h3>
    <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
      @submit="submit"
    >
      <template slot="id">
        <el-button
          type="text"
          v-if="JSON.stringify(form.regionId) == '{}'"
          @click="handleSelect1"
        >请选择</el-button>
        <el-button
          type="text"
          v-else
          @click="handleSelect1"
        >{{
          form.regionId.code
        }}</el-button>
      </template>

      <template slot="workerRegionId">
        <el-button
          type="text"
          v-if="JSON.stringify(form.regionUserId) == '{}'"
          @click="handleSelect"
        >请选择</el-button>
        <el-button
          type="text"
          v-else
          @click="handleSelect"
        >{{
          form.regionUserId.name
        }}</el-button>
      </template>

      <template slot="age">
        <el-input
          v-model="form.age"
          placeholder="请输入"
          type="text"
          onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
          @blur="salaryChange($event, 'age', 150)"
        />
      </template>

      <template slot="menuForm">
        <el-button
          icon="el-icon-close"
          @click="handleClose"
        >取消</el-button>
      </template>
    </avue-form>

    <SelectLink
      :show="show"
      :defaultData="form.regionUserId"
      @change="handleSelectData"
      @close="handleSelectClose"
    />
    <UserLink
      :show="show1"
      :defaultData="form.regionId"
      @change="handleSelectData1"
      @close="handleSelectClose1"
    />
  </div>
</template>

<script>
import { whiteAdd, whiteUpdate, whiteView } from "@/api/worker/index";
import SelectLink from "./components/selectLink";
import UserLink from "./components/userLink";
export default {
  name: "create",
  components: {
    SelectLink,
    UserLink,
  },
  data() {
    return {
      type: this.$route.query.type,
      id: this.$route.query.id,
      loading: false,
      form: {
        title: "基本信息",
        regionId: {},
        regionUserId: {},
        workerPhone:'',
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "",
            labelWidth: 20,
            type: "title",
            prop: "title",
            span: 24,
            styles: {
              fontSize: "16px",
              borderBottom: "1px solid #eee",
              paddingBottom: "10px",
            },
          },
          {
            label: "选择会员",
            prop: "id",
            span: 12,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择会员",
              },
            ],
          },
          {
            label: "姓名",
            prop: "workerUsername",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "年龄",
            prop: "age",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入年龄",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "性别",
            prop: "sex",
            type: "radio",
            value: 1,
            dicData: [
              { label: "男", value: 1 },
              { label: "女", value: 2 },
            ],
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "职位",
            prop: "workerPosition",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "workerPhone",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            row: true,
            disabled:true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (!/^1[3456789]\d{9}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "备用手机号",
            prop: "workerSparePhone",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入备用手机号",
            rules: [
              // {
              //   required: true,
              //   message: "请输入备用手机号",
              //   trigger: "blur",
              // },
              {
                validator: (rule, value, callback) => {
                  if (value && !/^1[3456789]\d{9}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "选择区域",
            prop: "workerRegionId",
            span: 12,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择区域",
              },
            ],
          },
        ],
      },
      show: false,
      selectData: {
        id: "",
      },
      show1: false,
    };
  },
  mounted() {
    this.$route.query.id && this.getView();
  },
  methods: {
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            if (value < 1) {
              this.form[t] = 1;
              e.target.value = 1;
              return;
            }
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    getView() {
      this.loading = true;
      whiteView({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.form = {
            ...res.data,
          };

          this.form.regionId = {
            id: res.data.id,
            code: res.data.code,
          };
          this.form.regionUserId = {
            id: res.data.workerRegionId,
            name: res.data.regionName,
          };
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    submit(form, done) {
      let data = {
        id: this.form.regionId.id,
        workerUsername: this.form.workerUsername,
        workerPosition: this.form.workerPosition,
        age: this.form.age,
        sex: this.form.sex,
        workerPhone: this.form.workerPhone,
        workerRegionId: this.form.regionUserId.id,
        workerSparePhone:this.form.workerSparePhone
      };

      if (this.id) {
        whiteUpdate(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        whiteAdd(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    handleClose() {
      this.$router.go(-1);
    },

    // 会员选择
    handleSelect1() {
      if (this.type === "create") {
        this.show1 = true;
      }
    },
    handleSelectData1(e) {
      this.form.regionId = e;
      this.form.id = e.id;
      // this.form.handleSelectData1 = e.phone
      this.form.workerPhone = e.phone
      this.handleSelectClose1();
    },

    handleSelectClose1() {
      this.show1 = false;
    },

    // 区域选择
    handleSelect() {
      this.show = true;
    },
    handleSelectData(e) {
      this.form.regionUserId = e;
      this.form.workerRegionId = e.id; 
      this.handleSelectClose();
    },

    handleSelectClose() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
